body {
  font-family: "Inter", sans-serif;
  background-color: #0f062c;
  overflow-x: hidden;
}
.container {
  max-width: 1200px;

  margin: 0 auto;
}
.hover-custom {
  transition: color 0.3s ease, transform 0.3s ease;
}

.hover-custom:hover {
  color: #a443e3 !important;
  cursor: pointer;
  /* transform: scale(1.02); */
}

.border-text {
  color: #0f062c;
  -webkit-text-stroke: 1px white;
  text-stroke: 1px white;
}

.button-hover:hover {
  background-color: #9a39e8;
  transition: background-color 0.3s ease;
}
.text-xxs {
  font-size: 0.65rem;
}
